import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function HomePage() {

  const [related, setRelated] = useState([]);

  useEffect(() => {
    document.title = 'SamplezPack - Royalty free sound sample, sound effects.';
    window.scrollTo(0, 0);
    fetchDataRelated();
  }, []);

  const CreateRelated = ({ data }) => {
    if (data !== null || data !== undefined) {


      function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            onClick={onClick}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>

          </div>
        );
      }

      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (

          <div
            className={className}
            onClick={onClick}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </div>
        );
      }


      var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };
      return (
        <>
          <div className='py-10 bg-black'>
            <div className='text-center my-4 md:container mx-auto'>
              <h2 className='text-center text-3xl'>Latest Samples</h2>
            </div>
            <div className='md:container mx-auto my-7 mb-9 gap-6'>
              <div className='mx-8 md:-mx-3'>
                <Slider {...settings}>
                  {Object.entries(data).map(([k, item]) => (
                    <div className='p-3 relative' key={k}>
                      <NavLink to={'/product/' + item.slug}>
                        <div className='absolute top-4 right-4'>
                          <span className='block mb-2 py-1 px-4 bg-sp-pink rounded-full text-xs'>{item.categories[0].cat_name}</span>
                          {/*Object.entries(item.categories).map(([kk, ii]) => (
                            <span key={kk} className='block mb-2 py-1 px-4 bg-sp-pink rounded-full text-xs'>{ii.cat_name}</span>
                          ))*/}
                        </div>
                        <img alt={item.name} className='aspect-square rounded-lg w-full' src={'https://cdn2.samplezpack.com/image/400x400/webp/' + item.image} />
                        <h4 className='mt-2 font-normal font-sans text-center truncate'>{item.name}</h4>
                      </NavLink>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  const fetchDataRelated = () => {
    axios({
      method: 'get',
      url: 'https://api.samplezpack.com/products?offset=0',
    }).then(function (response) {
      setRelated(response.data.data.data);
    })
  }


  return (
    <>
      <Helmet>
        <title>SamplezPack - Royalty Free Sample Packs</title>
        <meta name="title" content="SamplezPack  - Royalty Free Sample Packs" />
        <meta name="description" content="Download high quality royalty free samples, multiple genre music loops from around the world. โหลดเสียง Sample โหลดเสียง Effect ฟรี" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://samplezpack.com/" />
        <meta property="og:title" content="SamplezPack  - Royalty Free Sample Packs" />
        <meta property="og:description" content="Download high quality royalty free samples, multiple genre music loops from around the world." />
        <meta property="og:image" content="https://samplezpack.com/assets/images/sample pack reyalty free.jpg" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://samplezpack.com/" />
        <meta property="twitter:title" content="SamplezPack  - Royalty Free Sample Packs" />
        <meta property="twitter:description" content="Download high quality royalty free samples, multiple genre music loops from around the world." />
        <meta property="twitter:image" content="https://samplezpack.com/assets/images/sample pack reyalty free.jpg" />
      </Helmet>
      <div className="relative overflow-hidden flex flex-col justify-center items-stretch h-h40 md:h-h85"
        style={{
          backgroundImage: 'linear-gradient( 180deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1) ), url("/assets/images/sample pack reyalty free-5475491.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}>
        <div className='grow flex justify-center items-center'>
          <div className='text-center '>
            <h1 className='text-center text-5xl md:text-7xl text-white glow-heading'>
              <small>Royalty Free</small><br />Sample Packs
            </h1>
            <p className='text-center mt-4'>Download high quality royalty free samples,<br />multiple genre music loops from around the world.</p>
            <NavLink to='/products/' className="mt-4 inline-block rounded-md border border-transparent bg-sp-pink py-3 px-8 text-center font-medium text-white hover:bg-indigo-700">
              Find your Samples
            </NavLink>
          </div>
        </div>
        <div className='flex justify-center items-center pb-8'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 home-scroll">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      </div>
      <CreateRelated data={related} />
    </>
  )
}
