import { BrowserRouter as Router, Switch, Route, Link, } from "react-router-dom";
const Footer = () => {
    return (
        <div className="px-8 bg-black border-t border-gray-800">
            <div className='py-5 border-b border-gray-800 flex items-center justify-center'>
                <h5 className='text-xl text-neutral-300'>Need help? Contect us at support@samplezpack.com (TH/EN)</h5>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 py-12 text-gray-300 gap-8 text-center md:container mx-auto">
                <div className="max-w-sm">
                    <h4>Sample • Z • Pack</h4>
                    <p>We collect best quality sound sample multiple genres such as Pop, Rock, Dance, Electronic and many more for you download.</p>
                    <p>Royalty Free Sample Packs Download high quality royalty free samples, multiple genre music loops from around the world.</p>
                </div>
                <div>
                    <h4>Genres</h4>
                    <ul className="leading-7">
                        <li className="border-b border-neutral-900"><Link to="/products/live-soundtrack">Live / Soundtrack</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/hip-hop-and-trap">Hip Hop & Trap</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/house">House</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/pop-dance-edm">Pop / Dance / Edm</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/bass-music">Bass Music</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/global">Global</Link></li>
                        <li><Link to="/products/sound-effects">Sound Effects</Link></li>
                    </ul>
                </div>
                <div>
                    <h4>Labels</h4>
                    <ul className="leading-7">
                        <li className="border-b border-neutral-900"><Link to="/products/brand/splice-sounds">Splice Sounds</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/brand/ghosthack">Ghosthack</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/brand/hy2rogen">Hy2rogen</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/brand/just-sound-effects">Just Sound Effects</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/brand/samplezpack">Samplezpack</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/products/brand/big-edm">Big EDM</Link></li>
                        <li><Link to="/products/brand/producer-loops">Producer Loops</Link></li>
                    </ul>
                </div>
                <div>
                    <h4>Account</h4>
                    <ul className="leading-7">
                        <li className="border-b border-neutral-900"><Link to="/my-account">My Account</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/my-account/reset-password">Change password</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/my-account/my-subscription">My Subscription</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/my-account/download-history">Download History</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/auth/forgot-password">Forgot Password?</Link></li>
                        <li className="border-b border-neutral-900"><Link to="/auth/login">Sign in!</Link></li>
                        <li><Link to="/auth/register">Sign up</Link></li>
                    </ul>
                </div>
            </div>
            <div className='py-5 border-t border-gray-800 flex items-center justify-center'>
                <div className='text-gray-400'>© 2022 samplezpack.com All Rights Reserved.</div>
            </div>
        </div>
    );
}
export default Footer;