import React, { lazy, Suspense } from "react";

import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import AppHeader from "./components/header";
import Footer from "./components/Footer";
import HomePage from "./pages/home";

import LinearProgress from '@mui/material/LinearProgress';

const Products = lazy(()=> import("./pages/products"));
const Product = lazy(() => import("./pages/product"));
const Pricing = lazy(() => import("./pages/pricing"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword"));
const SignIn = lazy(() => import("./pages/signin"));
const SignUp = lazy(() => import("./pages/signup"));
const ForgotPasswordReset = lazy(() => import("./pages/forgotPasswordReset"));
const Payment = lazy(() => import("./pages/payment"));
const Thankyou = lazy(() => import("./pages/thankyou"));
const DownloadLimit = lazy(() => import("./pages/downloadLimit"));
const MyAccount = lazy(() => import("./pages/myaccount"));
const DownloadHistory = lazy(() => import("./pages/downloadHistory"));
const MySubscription  = lazy(() => import("./pages/mySubscription"));
const ResetPassword = lazy(() => import("./pages/resetpassword"));

function App() {
  return (
    <Router>
      <AppHeader />
      <div className="mainContent bg-black text-gray-200 scroll-smooth">
        <Suspense fallback={<div className='fixed top-0 left-0 right-0' style={{ top: '0px', zIndex: '100' }}> <LinearProgress color='secondary' /> </div>}>
          <Routes>
            <Route path='/' element={<HomePage />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/products/:slug" element={<Products />}></Route>
            <Route path="/products/brand/:brand" element={<Products />}></Route>
            <Route path="/product/:slug" element={<Product />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/auth/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/auth/login" element={<SignIn />}></Route>
            <Route path="/auth/register" element={<SignUp />}></Route>
            <Route path="/forgot-password/reset/:hash" element={<ForgotPasswordReset />}></Route>
            <Route path="/payment" element={<Payment />}></Route>
            <Route path="/thankyou" element={<Thankyou />}></Route>
            <Route path="/error/download-limit" element={<DownloadLimit />}></Route>
            <Route path="/my-account" element={<MyAccount />}></Route>
            <Route path="/my-account/download-history" element={<DownloadHistory />}></Route>
            <Route path="/my-account/my-subscription" element={<MySubscription />}></Route>
            <Route path="/my-account/reset-password" element={<ResetPassword />}></Route>
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </Router >
  );
}

export default App;